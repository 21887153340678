import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import upgradePlan from 'src/assets/ManagePageIcons/upgradePlanSvg.svg';

const index = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'start'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignContent: 'start'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center'
          }}
        >
          <img src={upgradePlan} width={'60%'} />
        </Box>

        <Box
          sx={{
            width: '100%',
            mx: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <Typography
            sx={{
              width: '100%',
              fontSize: '20px',
              my: '6px',
              color: '#050505',
              fontWeight: 600,
              mx: 'auto'
            }}
          >
            Want to Access the Forms Feature?
          </Typography>
          <Typography sx={{ color: '#000000' }}>
            To unlock this feature, please upgrade your plan and get access to
            all the benefits that come with it!
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            mt: 2
          }}
          onClick={() => navigate('/plans')}
        >
          <Typography
            sx={{
              border: '1px solid #27AB69',
              width: 'fit-content',
              p: '8px',
              color: '#27AB69',
              borderRadius: '8px',
              fontWeight: 500,
              cursor: 'pointer',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                bgcolor: '#27AB69',
                color: '#fff'
              }
            }}
          >
            Upgrade Now
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default index;
