import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = ({ message, type }) => {
  const toastOptions = {
    position: 'top-right',
    autoClose: 2000, // 2 seconds
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    style: {
      borderRadius: '10px',
      padding: '16px',
      fontFamily: 'Poppins, sans-serif'
    }
  };

  const showToast = () => {
    switch (type) {
      case 'success':
        toast.success(message, toastOptions);
        break;
      case 'error':
        toast.error(message, toastOptions);
        break;
      case 'warning':
        toast.warn(message, toastOptions);
        break;
      case 'info':
        toast.info(message, toastOptions);
        break;
      default:
        toast(message, toastOptions);
        break;
    }
  };

  return (
    <>
      <ToastContainer
        style={{
          zIndex: 9999,
          position: 'absolute',
          fontFamily: 'Poppins, sans-serif'
        }}
      />
      {showToast()}
    </>
  );
};

export default Toast;
