import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Typography,
  styled,
  Button
} from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import NotificationsActiveTwoToneIcon from 'src/assets/svgIcons/ion_notifications.svg';
import AnalyticsApiCalls from 'src/utility/AnalyticsApiCalls';
import { formatDistanceStrict } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { getUnreadNotification } from 'src/redux/actions/wappAction';
import ChatAssignIcon from '../../../../../assets/notificationIcons/notificationChatAssignment.svg';
import { Link } from 'react-router-dom';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Toast from 'src/utility/Toast';
import { addAction, removeAction } from 'src/redux/features/wappSlice';

// Custom styled tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#333',
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: '4px 16px' // Reduced padding
  }
}));

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const notificationCount = useSelector(
    (state) => state.wapp.unreadNotification
  );

  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
    dispatch(removeAction({ payload: 0, type: 'unreadNotification' }));
  };

  const handleClose = () => {
    setOpen(false);
    handleCheckedAllButton();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const all = await AnalyticsApiCalls.getNotifications(5);
        if (all?.status === 200) {
          setNotifications(all.data);
        }
      } catch (error) {
        
      }
    };

    fetchData();
  }, [isOpen]);

  const handleCheckedAllButton = async () => {
    const allChecked = await AnalyticsApiCalls.checkAllNotifications();
    // removeAction(0, 'unreadNotification');
    dispatch(removeAction({ payload: 0, type: 'unreadNotification' }));
  };

  useEffect(() => {
    getUnreadNotification(dispatch);
  }, []);

  function formatCustomDistance(date1, date2) {
    const distance = formatDistanceStrict(date1, date2, { addSuffix: true });
    return distance
      .replace(' seconds', 's')
      .replace(' second', 's')
      .replace(' minutes', 'm')
      .replace(' minute', 'm')
      .replace(' hours', 'h')
      .replace(' hour', 'h')
      .replace(' days', 'd')
      .replace(' day', 'd')
      .replace(' about ', '') // Optionally remove 'about' for more compact output
      .replace(' less than a', '<1')
      .replace(' almost ', ''); // Optional, based on how much you want to simplify
  }

  return (
    <>
      <CustomTooltip arrow title="Notifications">
        <IconButton
          color="primary"
          ref={ref}
          onClick={handleOpen}
          sx={{ '&:hover': { background: 'transparent' } }}
        >
          {notificationCount > 0 && (
            <NotificationsBadge
              badgeContent={parseInt(notificationCount)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <img src={NotificationsActiveTwoToneIcon} alt="" />
            </NotificationsBadge>
          )}
          {notificationCount === 0 ? (
            <img src={NotificationsActiveTwoToneIcon} alt="" />
          ) : null}
        </IconButton>
      </CustomTooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: {
            maxWidth: { xs: 350, sm: 550 },
            mt: 2
          }
        }}
      >
        <Box
          sx={{ paddingX: '10px', paddingY: '5px' }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontSize="16px" fontWeight="600">
            Notifications
          </Typography>
          <Button
            component={Link}
            to="/notifications"
            variant="text"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{
              fontSize: '12px',
              background: 'none',
              fontWeight: 500,
              cursor: 'pointer',
              textDecoration: 'none'
            }}
            onClick={() => handleClose()}
          >
            See All
          </Button>
        </Box>
        <Divider />
        <List sx={{ mb: '20px', maxHeight: 250, overflowY: 'scroll' }}>
          {notifications?.map((notification) => (
            <ListItem
              key={notification._id}
              sx={{
                p: 1,
                mb: 0.5,
                minWidth: 280,
                display: 'flex',
                flexDirection: 'column', // Stack the content vertically
                bgcolor: notification.status === 'read' ? '#FFFFFF' : '#DEFFE2',
                borderRadius: '10px'
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                sx={{ width: '100%' }}
              >
                <img
                  src={ChatAssignIcon}
                  height="20px"
                  alt="Chat Assign Icon"
                />
                <Typography
                  sx={{
                    flexGrow: 1, // Allows the message to take up remaining space
                    maxWidth: 'calc(100% - 80px)', // Adjust this value based on total width minus icon and some padding
                    overflow: 'visible'
                  }}
                >
                  {notification.message}
                </Typography>

                <Typography
                  sx={{
                    alignItems: 'center',
                    // textTransform: 'none',
                    fontSize: '10px',
                    fontWeight: 400,
                    color: '#959595',
                    alignSelf: 'flex-end' // Aligns the timestamp to the right
                  }}
                >
                  {formatCustomDistance(
                    new Date(notification.createdAt),
                    new Date(),
                    { addSuffix: true }
                  )}
                </Typography>
              </Box>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
