import axios from 'axios';
import { axiosInstance } from './axios';

const saveAutomation = async (automationId, nodes, edges) => {
  try {
    const response = await axiosInstance.post('automation/saveAutomation', {
      automationId,
      nodes,
      edges
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getAllAutomations = async () => {
  try {
    const response = await axiosInstance.get('automation/fetchAllAutomations');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const createAutomation = async (automationName) => {
  try {
    const response = await axiosInstance.post('automation/createAutomation', {
      automationName
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const fetchAutomationById = async (automationId) => {
  try {
    const response = await axiosInstance.get(
      'automation/fetchAutomationById?automationId=' + automationId
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const deleteAutomationById = async (automationId) => {
  try {
    const response = await axiosInstance.post(
      'automation/deleteAutomationById',
      { automationId }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const updateAutomationStatus = async (automationId, newStatus) => {
  try {
    const response = await axiosInstance.post(
      'automation/updateAutomationStatusById',
      { automationId, newStatus }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const editAutomationName = async (automationId, automationName) => {
  try {
    const response = await axiosInstance.post('automation/editAutomationName', {
      automationId,
      automationName
    });
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const getDefaultAutomation = async () => {
  try {
    const response = await axiosInstance.get('automation/getDefaultAutomation');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const setDefaultAutoamtion = async (automationId) => {
  try {
    const response = await axiosInstance.post(
      'automation/setDefaultAutoamtion',
      { automationId }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const endpointTestApi = async (api) => {
  try {
    const response = await axios.get(api);
    return response;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};


const addChatbotAttribute = async (attributeName) => {
  try {
    const response = await axiosInstance.post(
      'automation/addChatbotAttribute',
      { attributeName }
    );
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const listChatbotAttributes = async () => {
  try {
    const response = await axiosInstance.get('automation/listChatbotAttributes');
    return response.data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

const AutomationApiCalls = {
  saveAutomation,
  getAllAutomations,
  createAutomation,
  fetchAutomationById,
  deleteAutomationById,
  updateAutomationStatus,
  editAutomationName,
  getDefaultAutomation,
  setDefaultAutoamtion,
  endpointTestApi,
  listChatbotAttributes,
  addChatbotAttribute
};

export default AutomationApiCalls;
