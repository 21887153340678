import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  styled
} from '@mui/material';

// import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import ProfileAvatar from 'src/assets/MyProfileIcon.svg';
// import ChnagePasswordAvatar from 'src/assets/Group.png';
import ChangePassword from 'src/assets/ChangePasswordIcon.svg';
import SignOutAvatar from 'src/assets/SignOut.svg';
import { useAuth } from 'src/Authentication/AuthContext';
import NotificationIcon from 'src/assets/NotificationIcon.svg';
import { useDispatch } from 'react-redux';
import  {resetState}  from 'src/redux/features/wappSlice';
import { getDecryptedData } from 'src/utility/LocalStorageManager';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(1)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)`
  font-weight: 500;
  color: #111111;
  display: block;
  font-size: 15px;
`;

const UserBoxDescription = styled(Typography)`
  font-weight: 400;
  color: #a3a3a3;
  display: block;
  font-size: 12px;
`;

const apiKey = process.env.REACT_APP_API_URL;

function HeaderUserbox() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { managerLogout, profile } = useAuth();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const getAdminInfo = () => {
          const managerInfo = getDecryptedData('managerInfo');
    

    return managerInfo;
  };

  const managerInfo = getAdminInfo();

  useEffect(() => {
    getAdminInfo();
  }, [profile]);

  // const user = {
  //   name: userInfo?.name,
  //   avatar: '/static/images/avatars/1.jpg',
  //   jobtitle: 'Project Manager'
  // };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleMyProfileClick = () => {
  //   navigate('/profile');
  //   handleClose();
  // };

  // const handleChangePassword = () => {
  //   navigate('/profile');
  //   handleClose();
  // };

  // const handleOpenNotifications = () => {
  //   navigate('/notifications');
  //   handleClose();
  // };

  const handleLogout = () => {
    managerLogout();
    dispatch(resetState());

    // sessionStorage.removeItem('token')
    navigate('/admin/login');
  };

  return (
    <>
      {/* <Box sx={{alignContent:'center',mr:'5px'}}>
      <img src={notification} alt="notification icon"/>
    </Box> */}
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="circular"
          src={`${apiKey}${managerInfo?.profilePhoto}`}
          alt={managerInfo.name}
        />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel>{managerInfo.name}</UserBoxLabel>
            <UserBoxDescription>{managerInfo.email}</UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{ marginTop: '5.5%' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {/* <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{userInfo.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {userInfo.role_id}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox> */}
        {/* <Divider sx={{ mb: 0 }} /> */}
        {/* <List sx={{ p: 1 }} component="nav">
          <ListItemButton onClick={handleMyProfileClick}>
            <img
              src={ProfileAvatar}
              style={{ height: '15px' }}
              alt={managerInfo.name}
            />
            <ListItemText sx={{ ml: 1 }} primary="My Profile" />
          </ListItemButton>
          <ListItemButton onClick={handleOpenNotifications}>
            <img
              src={NotificationIcon}
              style={{ height: '16px' }}
              alt="NotificationIcon"
            />
            <ListItemText sx={{ ml: 1 }} primary="Notifications" />
          </ListItemButton>
          <ListItemButton onClick={handleChangePassword}>
            <img
              src={ChangePassword}
              style={{ height: '15px' }}
              alt="ChangePasswordIcon"
            />
            <ListItemText sx={{ ml: 1 }} primary="Change Password" />
          </ListItemButton> */}



          {/* <ListItem button to="/dashboards/messenger" component={NavLink}>
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText primary="Messenger" />
          </ListItem> */}
          {/* <ListItem
            button
            to="/management/profile/settings"
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary="Account Settings" />
          </ListItem> */}
        {/* </List> */}
        <Divider />
        <List sx={{ p: 1 }} component="nav">
          <ListItemButton onClick={handleLogout}>
            {/* <LockResetIcon color="#dc2626" sx={{ mr: 1, color:'#dc2626' }} fontSize="small" /> */}
            <img
              src={SignOutAvatar}
              style={{ height: '15px' }}
              alt={managerInfo.name}
            />
            <ListItemText
              color="#dc2626"
              sx={{ color: '#dc2626', fontWeight: 600, ml: 1 }}
              primary="Sign out"
            />
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
