import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAQU0bd8G_W-KVcGPjnHzll8F1cffUNWnU',
  authDomain: 'wappbizz.firebaseapp.com',
  projectId: 'wappbizz',
  storageBucket: 'wappbizz.appspot.com',
  messagingSenderId: '887015990169',
  appId: '1:887015990169:web:48a09ab3c5c8b10a772908',
  measurementId: 'G-PF3W84ELC9'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
const messaging = getMessaging(app);

// Foreground message handling
onMessage(messaging, (payload) => {
  
   // Example: Display notification content in the app's interface
  // if (Notification.permission === 'granted') {
  //   new Notification(payload.notification.title, {
  //     body: payload.notification.body,
  //     icon: 'https://api.wapp.biz/uploads/Wappbiz-256x256.png',
  //       sound : "default"
  //   });
  // }
});

export { messaging, getToken };
