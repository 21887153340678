import axios from 'axios';
import { getDecryptedData } from './LocalStorageManager';

const apiKey = process.env.REACT_APP_API_URL;

export const axiosInstance = axios.create({ 
  // baseURL: 'http://localhost:80/api/'

     baseURL: `${apiKey}/api/`   

  // baseURL: 'http://103.217.221.23:7979/api/'
});
export const axiosAdminInstance = axios.create({ 
  // baseURL: 'http://localhost:80/api/'

     baseURL: `${apiKey}/api/`   

  // baseURL: 'http://103.217.221.23:7979/api/'
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve token from session storage
    // const userInfo = sessionStorage.getItem('userInfo')
    const userInfo =  getDecryptedData('userInfo')
      // ? JSON.parse(sessionStorage.getItem('userInfo'))
      // : null;

    // Check if token exists before adding the Authorization header
    if (userInfo?.token) {
      config.headers['Authorization'] = `${userInfo.token}`;
      config.withCredentials = true;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosAdminInstance.interceptors.request.use(
  (config) => {
    // Retrieve token from session storage
    // const userInfo = sessionStorage.getItem('userInfo')
    const userInfo = JSON.parse(sessionStorage.getItem("adminInfo"))||  getDecryptedData('managerInfo')
    if (userInfo?.token) {
      config.headers['Authorization'] = `${userInfo.token}`;
      config.withCredentials = true;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);