import { Box, Typography } from '@mui/material';
import React from 'react';
import NotVerified from 'src/assets/notVerified.svg';

const index = () => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '5px'
        }}
      >
        <img src={NotVerified} width={'60%'} height={'60%'} />
        <Typography sx={{fontSize:'18px', fontWeight:500, color:"#111"}}>Oops! Username Not Verified</Typography>
        <Typography>
          To unlock this feature, We’re here to assist—verify your username or
          reach out anytime.
        </Typography>
        <Typography sx={{
            mt:1,
            border: '1.5px solid #27AB69',
            py:'5px',
            px:'10px',
            borderRadius:'6px',
            cursor:'pointer',
            color:'#27AB69',
            fontWeight:500
        }}>Contact Us</Typography>
      </Box>
    </Box>
  );
};

export default index;
